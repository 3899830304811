























































































import {Component, Vue} from 'vue-property-decorator';
import { IClaim, IExplainClaimData } from '@/interfaces';
import {getFullDate} from '@/utilities';
import Backlink from '@/components/Backlink.vue';
import store from '@/store';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import Uploader from '@/components/Ui/Uploader/Uploader.vue';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Backlink,
    Uploader
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('getClaim', to.params.id)
    .finally(() => next());
  },
})
export default class ClaimExplain extends Vue {
  private getFullDate = getFullDate;

  private explainClaimData: IExplainClaimData = {
    claimId: this.$route.params.id,
    justificationMessage: '',
  };

  private get getClaim(): IClaim {
    return this.$store.getters.getClaim;
  }

  private get formatRefNumber() {
    return (refNumber) => '000000'.slice(String(refNumber).length) + refNumber;
  }

  private get attachments() {
    return this.getClaim && this.getClaim.tenantDocuments
      ? this.getClaim.tenantDocuments
      : [];
  }

  private onSubmit() {
    this.$store.dispatch('explainClaim', this.explainClaimData)
      .then(() => {
        this.$notify({
          group: 'notification',
          type: 'notification-success',
          text: this.$tc('claim.notifications.explain_success')
        });
        this.$router.go(-1);
      });
  }

  private formatCurrency(amount, currency = 'EUR') {
    return this.$options.filters?.[currency](amount) ?? amount;
  }

  private goBack() {
    this.$store.dispatch('clearClaimFiles');
    this.$router.go(-1);
  }

  private uploadClaimTenantDocuments(file: File) {
    return this.$store.dispatch(
      'uploadClaimTenantDocuments',
      {file, id: this.$route.params.id}
    );
  }

  private removeClaimTenantDocuments(documentId: string) {
    return this.$store.dispatch(
      'removeClaimTenantDocuments',
      {documentId, id: this.$route.params.id}
    );
  }
}
