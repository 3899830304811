









import { Component, Vue } from 'vue-property-decorator';
import BacklinkIcon from '@/assets/icons/backlink.svg';

@Component({
  components: { BacklinkIcon }
})
export default class Backlink extends Vue {}
